.rowTableInfo {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
  height: calc(100% - 50px);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.contentHidden {
  display: none;
}
.buttonTab {
  width: 100%;
}

.tabs {
  display: flex;
  padding: 5px;
  gap: 5px;
  button {
    border-radius: 4px;
    background-color: #667a8a3b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 171.429%;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    padding: 9px 20px 6px 20px;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.15s ease-in-out;
  }

  .active {
    background-color: #667a8a;
    color: white;
  }
}
