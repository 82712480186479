.wrapper {
  padding-top: 20px;
  transition: 0.3s all;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #d8d8d8;
}
.navBar {
  transition: 0.3s all;
  background: white;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 10px 12px;
  width: 100%;
}
.navBarOpen {
  min-width: 260px;
  max-width: 260px;
}

.navBarCollops {
  min-width: 80px;
  max-width: 80px;
}
.navLink {
  position: relative;
  display: flex;
}
.links {
  display: flex;
  flex-direction: column;

  & a {
    padding: 5px 12px;
    background: transparent;
    width: 100%;
    color: #667a8a;
    margin-bottom: 17px;
    font-weight: 400;
    border-radius: 4px;
    font-size: 17px;
    display: flex;
    align-items: center;

    transition:
      color 0.15s ease,
      background 0.15s ease;

    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background: lighten(#667a8a, 20%);
      color: white;
    }
    &:global.active {
      background: #667a8a;
      color: white;
      font-weight: 600;
    }
    &:active {
      background: #667a8a;
      color: white;
    }
  }
}

.notifications {
  position: absolute;
  top: -10px;
  right: -10px;
  display: inline-block;
  width: 22px;
  height: 22px;
  background: #d32f2f;
  margin-left: 9px;
  border-radius: 100%;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 22px;
  color: white !important;
}

.collops {
  transform: rotate(180deg);
}
.openedCollops {
  height: 23px;
  transition: 0.3s all;
}

.between {
  justify-content: space-between;
}

.center {
  justify-content: center;
}

.navBarButton {
  min-width: 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  background: #667a8a;
  text-align: center;
  color: white;
  &:hover {
    background: #667a8a;
    color: white;
  }
}
.email {
  font-weight: 600;
  font-size: 15px;
}
.text {
  text-align: start;
  max-height: 23px;
  transition: 0.7s all;
}

.textCollops {
  transition: 0.6s all;
  opacity: 0;
  width: 0;
}
.img {
  height: 65px;
  width: 65px;
}
.logoText {
  font-size: 24px;
  text-transform: uppercase;
  display: inline;
  font-weight: 700;
  width: 120px;
}

.logo {
  gap: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chipWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.navBarWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.versionContainer {
  display: flex;
  flex-direction: column; 
  gap: 10px;
  margin-left: 20px; 
  margin-bottom: 20px;
}

.versionItem {
  font-size: 16px;
  font-style: italic;
  color: gray;
}

.sliderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-top: 10px;
}