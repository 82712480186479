.imageItem {
  position: relative;
  border-radius: 5px;
  transition: 0.4s all;
  overflow: hidden;
  height: 183px;
  width: 183px;
  & img {
    height: 183px;
    width: 183px;
    object-fit: cover;
  }
}

.animateOnFirstRender {
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-name: renderImage;
}

@keyframes renderImage {
  from {
    scale: 0.5;
  }

  to {
    scale: 1;
  }
}

.clock {
  position: absolute;
  top: 40%;
  left: 40%;
  font-size: 70px;
}

.skeleton {
  transform: translateY(1px);
  height: 185px;
}

.imageWrapper {
  display: block;
  height: 185px;
  width: 185px;
  &::before {
    content: '';
  }
}

.actions {
  color: white;
  transition: 0.3s all;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 102;
  & span {
    transition: 0.3s all;
    width: 32%;
    height: 32%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.644);
      & svg {
        font-size: 45px;
      }
    }
  }
  & svg {
    font-size: 30px;
    transition: 0.1s all;
    opacity: 0;
    // &:hover {
    // }
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.459);
    & svg {
      opacity: 1;
    }
  }
}

.avatar {
  display: flex;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 103;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
}
