.popup {
  position: fixed;
  display: flex;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgba(22, 37, 61, 0.5);
  overflow: auto;
}

.size {
  min-width: 550px;
  max-height: 99vh;
}

.substr {
  background: white;
  border-radius: 16px;
  padding: 20px 0 28px;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.close {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 14px;
}

.header {
  margin-bottom: 10px;
  padding: 0 28px;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.body {
  flex: 1;
  font-size: 16px;
  padding: 0px 28px;
  overflow: auto;
}
.buttons {
  padding: 0 28px;
  display: flex;
  justify-content: flex-end;

  button {
    &:first-child {
      margin-right: 16px;
    }
  }
}
