.paddings {
  padding: 20px 25px 0;
}
.card {
  width: 580px;
}
.zeroPaddings {
  padding: 0;
}
.info {
  padding: 10px 10px 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label {
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  & svg {
    margin-left: 5px;
  }
}
.value {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 50%;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-weight: 600;
  font-size: 16px;
}

.labelText {
  font-size: 16px;
  font-weight: 500;
}
.wrapperText {
  max-width: 285px;
}

.labelWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
