.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;

    &:first-child {
      color: #00000099;
    }
    &:last-child {
      color: #000000de;
    }
  }
}

.td {
  width: 100%;
}
